import React from 'react';
import biking from "../biking-flipped.png"
import Github from './Github';
import leaning from '../leaning.png';
import cloud from '../cloud.png';
import db from '../db.png';
// import './About.css'; 

function About() {
  return (
    <div className="main-content" id="about">
      <div className="pics-container">
      <div className="about-content">
        <h1>Allow Me To Introduce Myself</h1>
        <p>
        Hello! I'm Kirolos Youssef, a passionate software engineer with a focus on 
          building engaging and performant web applications. I have a background 
          in Machine Learning and Data Science, and I'm skilled in technologies such as 
          JavaScript, React, Node.js, just to name a few.
          </p>
          <p>
          My programming journey started in high school, where I first got my hands on Java applets, mimicking iPod functionalities. It was an exciting time, experimenting and learning on the fly. 
          This curiosity soon led me to programming Arduinos, making small robots that could follow a black line – it felt like bringing sci-fi to life! 
          My passion continued to grow at Toronto Metropolitan University, where I immersed myself in diverse projects, shaping my skills in a real-world context. 
          My internship at Kinaxis was a game-changer. As an Intern Solution Architect, I delved into Typescript, refined my problem-solving prowess, and played a pivotal role in client facing software development processes. 
          It's been a journey of endless learning, from building brain tumor segmentation models with PyTorch to embracing the latest in tech trends. 
          I thrive on challenges and love bringing innovative ideas to life! 
        </p>
        <p>
          When I'm not coding, you might find me Biking, Scuba Diving or Weight Lifting, 
          which helps me maintain a balanced and creative approach to my work.
        </p>
        <p>
          Feel free to browse my portfolio to see some of the projects I've 
          worked on. I'm always open to new opportunities and collaborations, 
          so don't hesitate to get in touch!
        </p>
        
      </div>
      <img src={cloud} alt="cloud" class="cloud-pic"/>
      <img src={db} alt="database" class="db-pic"/>
      
      <img src={leaning} alt="leaning" class="leaning-pic"/>
      <img src={biking} alt="man-biking" className="biking-img"/>
      </div>
      
      <Github className="git"/>
      
    </div>
    
  );
}

export default About;
